import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { StateContext } from '../../../../../StateProvider';
import { fetchPaymentHistoryByUserId } from '../../../../../Utilities/api';
import { BackButton } from '../../../../../Utilities/components/Buttons';
import './PaymentHistory.css';

const PaymentHistory = () => {
  const navigate = useNavigate();
  const { user } = useContext(StateContext);
  const [transactions, setTransactions] = useState([]);
  const [search, setSearch] = useState('');
  const [view, setView] = useState('all'); // Add 'all' as the default state
  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const itemsPerPage = 10; // Number of transactions per page

  useEffect(() => {
    const fetchPaymentHistory = async () => {
      if (user && user.uid) {
        try {
          const response = await fetchPaymentHistoryByUserId(user.uid);
          setTransactions(response);
        } catch (error) {
          console.error('Error fetching payment history:', error);
        }
      }
    };
    fetchPaymentHistory();
  }, [user]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1); // Reset to first page on search
  };

  const handleToggleView = (newView) => {
    setView(newView);
    setCurrentPage(1); // Reset to first page when toggling views
  };

  const filteredTransactions = (transactions || []).filter((transaction) => {
    const isPetNameMatch = transaction.selectedPets &&
      Array.isArray(transaction.selectedPets) &&
      transaction.selectedPets.some((pet) => {
        return pet.petName && pet.petName.toLowerCase().includes(search.toLowerCase());
      });
  
    return (
      (transaction.id && transaction.id.toString().includes(search)) ||
      (transaction.startTime && transaction.startTime.toString().includes(search)) ||
      (transaction.transactionStatus &&
        transaction.transactionStatus.toLowerCase().includes(search.toLowerCase())) ||
      isPetNameMatch ||
      (transaction.originalPrice && transaction.originalPrice.toString().includes(search)) ||
      (transaction.bookingType && transaction.bookingType.toLowerCase().includes(search.toLowerCase())) ||
      (transaction.status && transaction.status.toLowerCase().includes(search.toLowerCase()))
    );
  });  

  // Separate transactions into past and upcoming
  const pastTransactions = filteredTransactions.filter(
    (transaction) => new Date(transaction.startTime) < new Date()
  );
  const upcomingTransactions = filteredTransactions.filter(
    (transaction) => new Date(transaction.startTime) >= new Date()
  );

  // Determine which transactions to display based on the selected view
  const allTransactions = view === 'past'
    ? pastTransactions
    : view === 'upcoming'
    ? upcomingTransactions
    : filteredTransactions;

  // Pagination logic
  const totalPages = Math.ceil(allTransactions.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedTransactions = allTransactions.slice(startIndex, endIndex);

  const handlePageChange = (direction) => {
    if (direction === 'next' && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    } else if (direction === 'prev' && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="container">
      <div className="payment-history-section">
        <div className="filter-container">
          <BackButton />
          <input 
            type="text" 
            placeholder="Search" 
            value={search} 
            onChange={handleSearchChange} 
            className="payment-history-search-input"
          />
          <div className="payment-history-toggle-buttons">
            <button 
              className={`payment-history-toggle-button ${view === 'all' ? 'active' : ''}`}
              onClick={() => handleToggleView('all')}
            >
              All
            </button>
            <button 
              className={`payment-history-toggle-button ${view === 'past' ? 'active' : ''}`}
              onClick={() => handleToggleView('past')}
            >
              Past
            </button>
            <button 
              className={`payment-history-toggle-button ${view === 'upcoming' ? 'active' : ''}`}
              onClick={() => handleToggleView('upcoming')}
            >
              Upcoming
            </button>
          </div>
        </div>
        {displayedTransactions.length > 0 ? (
          <>
            {displayedTransactions.map((transaction, index) => (
              <div key={index}>
                <div
                  className="payment-history"
                  onClick={() =>
                    navigate(`/profile/wallet/payment-history/${transaction.id}`, { state: { transaction } })
                  }
                >
                  <div className="payment-history-details">
                    <div>
                      <p className="payment-history-type">
                        <strong>{transaction.bookingType}</strong> |{' '}
                        {transaction.selectedPets && Array.isArray(transaction.selectedPets)
                          ? transaction.selectedPets.map((pet) => pet.petName).join(', ')
                          : 'N/A'}
                      </p>
                      <p className="payment-history-date">
                        {transaction.startTime ? new Date(transaction.startTime).toLocaleDateString() : 'N/A'}
                      </p>
                    </div>
                    <div className="payment-history-status">
                      <p
                        className={`status-badge ${
                          transaction.status ? transaction.status.toLowerCase() : 'unknown'
                        }`}
                      >
                        {transaction.status ? transaction.status.toLowerCase() : 'unknown'}
                      </p>
                      <p className="payment-history-originalPrice">
                        ${parseFloat(transaction.originalPrice || 0).toFixed(2)}
                      </p>
                    </div>
                  </div>
                </div>
                <hr className="payment-history-breakline" />
              </div>
            ))}
            <div className="pagination-controls">
              <button
                className="pagination-button"
                disabled={currentPage === 1}
                onClick={() => handlePageChange('prev')}
              >
                Previous
              </button>
              <span className="pagination-info">
                Page {currentPage} of {totalPages}
              </span>
              <button
                className="pagination-button"
                disabled={currentPage === totalPages}
                onClick={() => handlePageChange('next')}
              >
                Next
              </button>
            </div>
          </>
        ) : (
          <div className="payment-history-message">
            No {view === 'past' ? 'past' : view === 'upcoming' ? 'upcoming' : 'all'} transactions available.
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentHistory;
