import axios from 'axios';
import stripePromise from '../../../stripe';
import imageCompression from 'browser-image-compression';

const API_BASE_URL = `https://www.pawsible.app` || 'http://localhost:6060';
// const API_BASE_URL = 'http://localhost:6060';

// User
export const fetchUserData = async (uid) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/users/${uid}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch user data:', error.response.data.message);
    throw error;
  }
};

export const fetchStaffMembers = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/users/staff`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch staff members:', error);
    throw error;
  }
};

export const fetchCustomers = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/users`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch customers:', error);
    throw error;
  }
};

export const setDefaultPaymentMethod = async (userId, paymentMethodId) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/api/users/${userId}/default-payment-method`, { paymentMethodId });
    return response.data;
  } catch (error) {
    console.error('Failed to fetch customers:', error);
    throw error;
  }
}

export const editMember = async (uid, userData) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/api/users/${uid}`, userData, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Failed to add family member:', error);
    throw error;
  }
};

export const updateProfileComplete = async (uid, profileCompleteStatus) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/api/users/${uid}/profileComplete`, {
      profileComplete: profileCompleteStatus
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Failed to update profileComplete status:', error);
    throw error;
  }
};

// Cumulative Financial Summary
export const fetchFinancialSummary = async () => {
  try {
      const response = await axios.get(`${API_BASE_URL}/api/finance/summary`);
      return response.data;
  } catch (error) {
      console.error('Failed to fetch total revenue:', error);
      throw error;
  }
};

// Frontend API function to update store location
export const updateStoreLocation = async (uid, storeLocation) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/api/users/${uid}/storeLocation`, {
      storeLocation
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Failed to update store location:', error);
    throw error;
  }
};

export const updateUserPhoneNumber = async (uid, phoneNumber) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/api/users/${uid}/update-phone`, { phoneNumber }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Failed to update user phone number:', error);
    throw error;
  }
};

// Family Members
export const addFamilyMember = async (familyMemberData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/family-members`, familyMemberData, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Failed to add family member:', error);
    throw error;
  }
};

export const editFamilyMember = async (familyMemberId, familyMemberData) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/api/family-members/${familyMemberId}/update`, familyMemberData, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Failed to update family member:', error);
    throw error;
  }
};

export const fetchFamilyMember = async (familyMemberId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/family-members/${familyMemberId}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch family member details:', error);
    throw error;
  }
};

export const fetchUserFamilyMembers = async (userId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/family-members/user/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch user family members:', error);
    throw error;
  }
};

export const deleteFamilyMember = async (familyMemberId) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/api/family-members/${familyMemberId}`);
    return response.data;
  } catch (error) {
    console.error('Failed to delete family member:', error);
    throw error;
  }
};

// Service
export const fetchService = async (serviceName) => {
  try {
      const response = await axios.get(`${API_BASE_URL}/api/service/${serviceName}`);
      return response.data;
  } catch (error) {
      console.error('Failed to fetch adventure details:', error);
      throw error;
  }
};

export const fetchAllServices = async () => {
  try {
      const response = await axios.get(`${API_BASE_URL}/api/service/getAllServices`);
      return response.data;
  } catch (error) {
      console.error('Failed to fetch adventure details:', error);
      throw error;
  }
};

// Bookings
export const fetchBooking = async (id) => {
  try {
      const response = await axios.get(`${API_BASE_URL}/api/booking/${id}`);
      return response.data;
  } catch (error) {
      console.error('Failed to fetch booking details:', error);
      throw error;
  }
};

export const fetchBookings = async () => {
  try {
      const response = await axios.get(`${API_BASE_URL}/api/booking`);
      return response.data;
  } catch (error) {
      console.error('Failed to fetch bookings:', error);
      throw error;
  }
};

export const fetchHotelAvailability = async (startDate, endDate) => {
  try {
      const response = await axios.get(`${API_BASE_URL}/api/booking/hotelAvailability`, {
        params: { startDate, endDate }
    });
      return response.data;
  } catch (error) {
      console.error('Failed to fetch availability:', error);
      throw error;
  }
};

export const getHotelAvailabilityByMonth = async (month, year) => {
  try {
      // Constructing the first day of the month and the last day of the month based on input month and year
      const startDate = new Date(year, month, 1); // This will give you the first day of the month
      const endDate = new Date(year, month + 1, 0); // This will give you the last day of the month

      const response = await axios.get(`${API_BASE_URL}/api/booking/hotelAvailability/${year}/${month}`, {
        params: { 
          startDate: startDate.toISOString().split('T')[0], // Format to YYYY-MM-DD
          endDate: endDate.toISOString().split('T')[0] // Format to YYYY-MM-DD
        }
      });

      return response.data;
  } catch (error) {
      console.error('Failed to fetch availability:', error);
      throw error;
  }
};

export const fetchDaycareAvailability = async (date) => {
  try {
      const response = await axios.get(`${API_BASE_URL}/api/booking/daycareAvailability`, {
        params: { date }
    });
      return response.data;
  } catch (error) {
      console.error('Failed to fetch availability:', error);
      throw error;
  }
};

export const getDaycareAvailabilityByMonth = async (month, year) => {
  try {
      // Constructing the first day of the month and the last day of the month based on input month and year
      const startDate = new Date(year, month, 1); // This will give you the first day of the month
      const endDate = new Date(year, month + 1, 0); // This will give you the last day of the month

      const response = await axios.get(`${API_BASE_URL}/api/booking/daycareAvailability/${year}/${month}`, {
        params: { 
          startDate: startDate.toISOString().split('T')[0], // Format to YYYY-MM-DD
          endDate: endDate.toISOString().split('T')[0] // Format to YYYY-MM-DD
        }
      });

      return response.data;
  } catch (error) {
      console.error('Failed to fetch availability:', error);
      throw error;
  }
};

export const getGroomingAvailabilityByMonth = async (month, year) => {
  try {
      // Constructing the first day of the month and the last day of the month based on input month and year
      const startDate = new Date(year, month, 1); // This will give you the first day of the month
      const endDate = new Date(year, month + 1, 0); // This will give you the last day of the month

      const response = await axios.get(`${API_BASE_URL}/api/booking/groomingAvailability/${year}/${month}`, {
        params: { 
          startDate: startDate.toISOString().split('T')[0], // Format to YYYY-MM-DD
          endDate: endDate.toISOString().split('T')[0] // Format to YYYY-MM-DD
        }
      });

      return response.data;
  } catch (error) {
      console.error('Failed to fetch availability:', error);
      throw error;
  }
};

export const getTrainingAvailabilityByMonth = async (month, year) => {
  try {
      // Constructing the first day of the month and the last day of the month based on input month and year
      const startDate = new Date(year, month, 1); // This will give you the first day of the month
      const endDate = new Date(year, month + 1, 0); // This will give you the last day of the month

      const response = await axios.get(`${API_BASE_URL}/api/booking/trainingAvailability/${year}/${month}`, {
        params: { 
          startDate: startDate.toISOString().split('T')[0], // Format to YYYY-MM-DD
          endDate: endDate.toISOString().split('T')[0] // Format to YYYY-MM-DD
        }
      });

      return response.data;
  } catch (error) {
      console.error('Failed to fetch availability:', error);
      throw error;
  }
};

export const getAdventureAvailabilityByMonth = async (month, year) => {
  try {
      // Constructing the first day of the month and the last day of the month based on input month and year
      const startDate = new Date(year, month, 1); // This will give you the first day of the month
      const endDate = new Date(year, month + 1, 0); // This will give you the last day of the month

      const response = await axios.get(`${API_BASE_URL}/api/booking/adventureAvailability/${year}/${month}`, {
        params: { 
          startDate: startDate.toISOString().split('T')[0], // Format to YYYY-MM-DD
          endDate: endDate.toISOString().split('T')[0] // Format to YYYY-MM-DD
        }
      });

      return response.data;
  } catch (error) {
      console.error('Failed to fetch availability:', error);
      throw error;
  }
};

export const getShuttleAvailabilityByMonth = async (month, year) => {
  try {
      // Constructing the first day of the month and the last day of the month based on input month and year
      const startDate = new Date(year, month, 1); // This will give you the first day of the month
      const endDate = new Date(year, month + 1, 0); // This will give you the last day of the month

      const response = await axios.get(`${API_BASE_URL}/api/booking/shuttleAvailability/${year}/${month}`, {
        params: { 
          startDate: startDate.toISOString().split('T')[0], // Format to YYYY-MM-DD
          endDate: endDate.toISOString().split('T')[0] // Format to YYYY-MM-DD
        }
      });

      return response.data;
  } catch (error) {
      console.error('Failed to fetch availability:', error);
      throw error;
  }
};

export const getTrainingTimeSlots = async (year, month, day, sessionType) => {
  try {
      const response = await axios.get(`${API_BASE_URL}/api/booking/training/timeSlots`, {
          params: { year, month, day, sessionType }
      });
      return response.data;
  } catch (error) {
      console.error('Error fetching training time slots:', error);
      throw error;
  }
};

// Service Configurations
export const fetchConfig = async (serviceName) => {
  try {
      const response = await axios.get(`${API_BASE_URL}/api/service-config/${serviceName}`);
      return response.data;
  } catch (error) {
      console.error('Failed to fetch configuration:', error.response ? error.response.data.message : error.message);
      throw error;
  }
};

export const updateConfig = async (serviceName, config) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/service-config/${serviceName}`, { serviceName, config });
    return response.data;
  } catch (error) {
    console.error('Failed to update configuration:', error.response ? error.response.data.message : error.message);
    throw error;
  }
}

// AWS S3
export async function uploadFileToS3(file) {
  try {
    // Define compression options
    const options = {
      maxSizeMB: 1,           // Max file size in MB
      maxWidthOrHeight: 800,  // Max width or height for resizing
      useWebWorker: true,
    };

    // Compress the file
    const compressedFile = await imageCompression(file, options);

    // Create form data with compressed file
    const formData = new FormData();
    formData.append('file', compressedFile);

    // Upload to S3
    const response = await axios.post(`${API_BASE_URL}/api/s3/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data.url;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
}

// Google Maps
export const fetchDistanceAndDuration = async (pickup, dropoff, departureTime) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/maps/distance-duration`, {
      params: { pickup, dropoff, departureTime }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching distance and duration:', error.response ? error.response.data.message : error.message);
    throw error;
  }
};

export const fetchShuttleAvailabilityForAirport = async (pickup, dropoff, departureTime, departureDate) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/maps/calculate-airport-availability`, {
      params: { pickup, dropoff, departureTime, departureDate }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching shuttle calculation and availability:', error.response ? error.response.data.message : error.message);
    throw error;
  }
};

export const fetchShuttleAvailabilityForDaycare = async (pickup, dropoff, departureTime, departureDate) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/maps/calculate-daycare-availability`, {
      params: { pickup, dropoff, departureTime, departureDate }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching shuttle calculation and availability:', error.response ? error.response.data.message : error.message);
    throw error;
  }
};

export const fetchShuttleAvailabilityForHotel = async (pickup, dropoff, departureTime, departureDate) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/maps/calculate-hotel-availability`, {
      params: { pickup, dropoff, departureTime, departureDate }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching shuttle calculation and availability:', error.response ? error.response.data.message : error.message);
    throw error;
  }
};

export const fetchShuttleAvailabilityForTraining = async (pickup, dropoff, departureTime, departureDate) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/maps/calculate-training-availability`, {
      params: { pickup, dropoff, departureTime, departureDate }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching shuttle calculation and availability:', error.response ? error.response.data.message : error.message);
    throw error;
  }
};

export const fetchShuttleAvailabilityForGrooming = async (pickup, dropoff, departureTime, departureDate) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/maps/calculate-grooming-availability`, {
      params: { pickup, dropoff, departureTime, departureDate }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching shuttle calculation and availability:', error.response ? error.response.data.message : error.message);
    throw error;
  }
};

export const fetchShuttleAvailabilityForAdventure = async (pickup, dropoff, departureTime, departureDate) => {
  try {
    const response = await axios.get('${API_BASE_URL}/api/maps/calculate-adventure-availability', {
      params: { pickup, dropoff, departureTime, departureDate }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching shuttle calculation and availability:', error.response ? error.response.data.message : error.message);
    throw error;
  }
};

// Cart
export const addCartItem = async (bookingDetails) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/cart`, bookingDetails);
    return response.data;
  } catch (error) {
    console.error('Error adding cart item:', error.response ? error.response.data.message : error.message);
    throw error;
  }
};

export const removeItemFromCart = async (itemId) => {
  try {
      const response = await axios.delete(`${API_BASE_URL}/api/cart/${itemId}`);
      return response.data;
  } catch (error) {
      throw new Error('Failed to remove item from cart');
  }
};

// Payment
export const addPaymentMethod = async (userId, paymentDetails) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/payment/${userId}/payment-method`, paymentDetails);
    return response.data;
  } catch (error) {
    console.error('Error adding payment method:', error.response ? error.response.data.message : error.message);
    throw error;
  }
};

export const getPaymentMethodById = async (paymentMethodId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/payment/${paymentMethodId}`);
    return response.data;
  } catch (error) {
    console.error('Error getting payment method by ID:', error.response ? error.response.data.message : error.message);
    throw error;
  }
};

export const updatePaymentMethod = async (paymentMethodId, updatedDetails) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/api/payment/${paymentMethodId}/update-method`, updatedDetails);
    return response.data;
  } catch (error) {
    console.error('Error updating payment method:', error.response ? error.response.data.message : error.message);
    throw error;
  }
};

export const deletePaymentMethod = async (paymentMethodId) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/api/payment/${paymentMethodId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting payment method:', error.response ? error.response.data.message : error.message);
    throw error;
  }
};

// Wallet
export const updateWalletBalance = async (userId, newBalance) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/api/wallet/${userId}/update-balance`, { balance: newBalance });
    return response.data;
  } catch (error) {
    console.error('Error updating wallet balance:', error.response ? error.response.data.message : error.message);
    throw error;
  }
};

// Stripe
export const processPayment = async (userId, amount, email, phone, defaultMethodId) => {
  try {
      const { data } = await axios.post(`${API_BASE_URL}/api/payment/${userId}/process-payment`, {
          amount,
          email,
          phone,
          defaultMethodId,
      });

      if (!userId) throw new Error('Failed to get user ID.');

      const clientSecret = data.clientSecret;
      if (!clientSecret) throw new Error('Failed to get client secret from server.');

      const stripe = await stripePromise;
      if (!stripe) throw new Error('Stripe is not initialized');

      // Confirm the payment using the payment method ID
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: defaultMethodId, // Using the saved payment method ID
      });

    if (result.error) {
        throw new Error(result.error.message);
    } else if (result.paymentIntent.status === 'succeeded') {
        return result.paymentIntent;
    } else {
        throw new Error('Payment failed. Please try again.');
    }
  } catch (error) {
      console.error('Error processing payment:', error.response ? error.response.data.message : error.message);
      throw error;
  }
};

export const processStripePayment = async (stripeCustomerId, paymentMethodId, cart, amount, currency, description, captureMethod, user) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/payment/${stripeCustomerId}/process-stripe-payment`, {
      paymentMethodId,
      amount,
      currency,
      description,
      captureMethod,
      cart,
      user
    });
    return response.data;
  } catch (error) {
    console.error('Failed to process payment:', error);
    throw error;
  }
};

export const createPayment = async (userId, amount, email, phone, paymentMethodId) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/payment/${userId}/create-payment-method`, {
      amount,
      email,
      phone,
      paymentMethodId,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const createStripeCustomer = async (userId, email) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/payment/${userId}/create-stripe-customer`, {
      email,
    });
    return response.data;
  } catch (error) {
    console.error('Failed to create Stripe customer:', error);
    throw error;
  }
};

export const attachPaymentMethodToCustomer = async (stripeCustomerId, paymentMethodId, userId) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/payment/${stripeCustomerId}/attach-payment-method`, {
      paymentMethodId,
      userId,
    });
    return response.data;
  } catch (error) {
    console.error('Failed to attach payment method:', error);
    throw error;
  }
};

export const capturePayment = async (paymentIntentId, amountToCapture) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/payment/capture`, {
      paymentIntentId,
      amountToCapture,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const cancelPayment = async (paymentIntentId) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/payment/cancel`, {
      paymentIntentId,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Payment History
export const fetchPaymentHistoryByUserId = async (userId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/booking/payment-history/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch payment history:', error);
    throw error;
  }
};

export const updateTransaction = async (bookingId, transaction) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/api/booking/payment-history/${bookingId}`, transaction);
    return response.data;
  } catch (error) {
    console.error('Failed to update transaction:', error);
    throw error;
  }
};

// Cancel Transaction
export const cancelTransaction = async (bookingId) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/api/booking/payment-history/${bookingId}`, {
      status: 'Canceled', // Update the status to "Canceled"
    });
    return response.data;
  } catch (error) {
    console.error('Failed to cancel transaction:', error);
    throw error;
  }
};

// Referral Redeem
export const redeemReferralCode = async (code) => {
  try {
      const response = await axios.post(`${API_BASE_URL}/api/users/redeem`, { 
        code 
      });
      return response.data;
  } catch (error) {
      throw error;
  }
};

// Memberships
export const getMembershipById = async (id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/memberships/${id}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch membership by ID:', error);
    throw error;
  }
};

export const updateMembership = async (id, updatedMembershipData, stripePaymentMethodId, stripeCustomerId, paymentDifference) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/api/memberships/${id}`, updatedMembershipData, stripePaymentMethodId, stripeCustomerId, paymentDifference);
    return response.data;
  } catch (error) {
    console.error('Failed to update membership:', error);
    throw error;
  }
};

export const deleteMembership = async (id) => {
  try {
    const response = await axios.patch(`${API_BASE_URL}/api/memberships/${id}/delete`);
    return response.data;
  } catch (error) {
    console.error('Failed to delete membership:', error);
    throw error;
  }
};

// Friends
export const fetchSuggestedFriends = async (uid) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/friends/suggestions/${uid}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch suggested friends:', error);
    throw error;
  }
};

// Twilio
export const sendVerificationCode = async (phoneNumber) => {
  try {
      const response = await axios.post(`${API_BASE_URL}/api/send-verification-code`, {
          phoneNumber,
      });
      if (response.data.success) {
          console.log('Verification code sent successfully.');
      } else {
          console.error(response.data.message);
      }
  } catch (error) {
      console.error('Error sending verification code:', error);
  }
};

export const verifyTwilioCode = async (phoneNumber, code) => {
  try {
      const response = await axios.post(`${API_BASE_URL}/api/verify-code`, {
          phoneNumber,
          code,
      });
      return response.data;
  } catch (error) {
      console.error('Error verifying code:', error);
      return { success: false, error: error.message };
  }
};
