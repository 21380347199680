import React, { useContext } from 'react';
import './Training.css';
import { StateContext } from '../../../StateProvider';
import { useNavigate } from 'react-router-dom';
import BookingSideIcon from '../../../Utilities/components/BookingSideIcon';

import trainingPageIcon from '../../../../assets/Store/Services/Training/trainingPageIcon.png';
import pageMainPhoto from '../../../../assets/Store/Services/pageMainPhoto.png';

import obedienceIcon from '../../../../assets/Store/Services/Training/trainingObedienceIcon.png';
import pottyIcon from '../../../../assets/Store/Services/Training/trainingPottyIcon.png';
import leashIcon from '../../../../assets/Store/Services/Training/trainingLeashIcon.png';
import behvaiorIcon from '../../../../assets/Store/Services/Training/trainingBehaviorIcon.png';
import socializationIcon from '../../../../assets/Store/Services/Training/trainingSocializationIcon.png';

import reinforcementIcon from '../../../../assets/Store/Services/Training/trainingReinforcementIcon.png';
import patienceIcon from '../../../../assets/Store/Services/Training/trainingPatienceIcon.png';
import consistencyIcon from '../../../../assets/Store/Services/Training/trainingConsistencyIcon.png';
import playfulIcon from '../../../../assets/Store/Services/Training/trainingPlayfulIcon.png';

import Footer from '../../../Footer';
import LetsGetStarted from '../../../Utilities/components/LetsGetStarted';

const Training = () => {
  const navigate = useNavigate();
  const { 
    loggedIn, user, setShowAddPetsModal
  } = useContext(StateContext);

  const handleBookingButtonClick = () => {
    if (loggedIn) {
      const hasPets = user.familyMembers.some(member => member.type === 'Dog' || member.type === 'Cat');
      if (hasPets) {
        navigate(`/store/services/training/booking`);
      } else {
        setShowAddPetsModal(true);
      }
    } else {
      navigate(`/login`);
    }
    window.scrollTo(0, 0);
  }

  return (
    <>
      <div className='training-background-one-container'>
        <div className='container'>
          <div className="training-container">
            <img className='training-page-icon' src={trainingPageIcon} />
            <h2 className='training-page-heading'>Unlock Potentials: Pawsitive Reinforcement</h2>
            <p className='training-page-paragraph'>Enhance obedience, behavior, and social skills of furry ones. Our experienced trainers use positive reinforcement for an enjoyable learning experience.</p>
            <div className="centered-container">
              <button className="training-page-button" onClick={handleBookingButtonClick}>BOOK A TRAINING</button>
            </div>
            <img className='training-page-main-photo' src={pageMainPhoto} />
          </div>
        </div>
      </div>  

      <div className='training-background-two-container'>
        <div className='container'>
          <h2 className='training-subpage-heading'>WHY CHOOSE PAWSIBLE</h2>
          <h3 className='training-subpage-heading-3'>Training Programs</h3>
          <ul>
            <li>
              <div className="training-services-item">
                <img className="training-services-item-icon" src={obedienceIcon}/>
                <div className="training-services-item-content">
                  <h4 className="training-services-item-heading">Basic Obedience</h4>
                  <p className="training-services-item-paragraph">
                    Teach your pet fundamental commands like sit, stay, come, and more to improve their behavior and responsiveness.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="training-services-item">
                <img className="training-services-item-icon" src={pottyIcon}/>
                <div className="training-services-item-content">
                  <h4 className="training-services-item-heading">Potty Training</h4>
                  <p className="training-services-item-paragraph">
                    Our trainers can help your pet with potty training to establish good bathroom habits.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="training-services-item">
                <img className="training-services-item-icon" src={leashIcon}/>
                <div className="training-services-item-content">
                  <h4 className="training-services-item-heading">Leash Walking</h4>
                  <p className="training-services-item-paragraph">
                    Learn how to walk your pet on a leash with ease, making walks enjoyable for both you and your pet.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="training-services-item">
                <img className="training-services-item-icon" src={behvaiorIcon}/>
                <div className="training-services-item-content">
                  <h4 className="training-services-item-heading">Behavioral</h4>
                  <p className="training-services-item-paragraph">
                    Address specific behavior issues, such as excessive barking, jumping, or aggression, to create a harmonious environment at home.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="training-services-item">
                <img className="training-services-item-icon" src={socializationIcon}/>
                <div className="training-services-item-content">
                  <h4 className="training-services-item-heading">Socialization</h4>
                  <p className="training-services-item-paragraph">
                    Help your pet become confident and comfortable around other animals and people through positive socialization experiences.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>


      <div className='training-background-three-container'>
        <div className='container'>
          <h2 className='training-approach-heading'>WHY CHOOSE PAWSIBLE</h2>
          <h3 className='training-approach-heading-3'>Training Approach</h3>
            <ul>
              <li>
                <div className="training-services-item">
                  <img className="training-services-item-icon" src={reinforcementIcon}/>
                  <div className="training-services-item-content">
                    <h4 className="training-services-item-heading">Positive Reinforcement</h4>
                    <p className="training-services-item-paragraph">
                      We use reward-based training methods to encourage positive behaviors and build trust between you and your pet.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="training-services-item">
                  <img className="training-services-item-icon" src={patienceIcon}/>
                  <div className="training-services-item-content">
                    <h4 className="training-services-item-heading">Patience and Care</h4>
                    <p className="training-services-item-paragraph">
                      Our trainers practice patience and understanding to cater to your pet's unique needs and pace during training sessions.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="training-services-item">
                  <img className="training-services-item-icon" src={consistencyIcon}/>
                  <div className="training-services-item-content">
                    <h4 className="training-services-item-heading">Consistency</h4>
                    <p className="training-services-item-paragraph">
                      We emphasize the importance of consistent training to reinforce learning and achieve long-lasting results.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="training-services-item">
                  <img className="training-services-item-icon" src={playfulIcon}/>
                  <div className="training-services-item-content">
                    <h4 className="training-services-item-heading">Playful Training</h4>
                    <p className="training-services-item-paragraph">
                      We incorporate interactive and fun exercises into training to make the learning process enjoyable for your pet.
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className='training-background-four-container'>
          <LetsGetStarted />
        </div>

        <Footer />

        <BookingSideIcon handleIconClick={handleBookingButtonClick} />
    </>
  );
};

export default Training;
