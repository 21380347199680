import React, { useContext } from 'react';
import './Adventure.css';
import { useNavigate } from 'react-router-dom';
import { StateContext } from '../../../StateProvider';
import BookingSideIcon from '../../../Utilities/components/BookingSideIcon';

import daycarePageIcon from '../../../../assets/Store/Services/Daycare/daycarePageIcon.png';
import pageMainPhoto from '../../../../assets/Store/Services/pageMainPhoto.png';

import hikingIcon from '../../../../assets/Store/Services/Adventures/adventureHikingIcon.png';
import beachIcon from '../../../../assets/Store/Services/Adventures/adventureBeachIcon.png';
import parkIcon from '../../../../assets/Store/Services/Adventures/adventureParkIcon.png';
import forestIcon from '../../../../assets/Store/Services/Adventures/adventureForrestIcon.png';
import mountainIcon from '../../../../assets/Store/Services/Adventures/adventureMountainIcon.png';

import photoIcon from '../../../../assets/Store/Services/Adventures/adventurePhotoIcon.png';
import essestialsIcon from '../../../../assets/Store/Services/Adventures/adventureEssentialsIcon.png';
import leashIcon from '../../../../assets/Store/Services/Adventures/adventureLeashIcon.png';
import groupSizeIcon from '../../../../assets/Store/Services/Adventures/adventureGroupSizeIcon.png';
import emergencyIcon from '../../../../assets/Store/Services/Adventures/adventureEmergencyIcon.png';

import Footer from '../../../Footer';
import LetsGetStarted from '../../../Utilities/components/LetsGetStarted';

const Adventure = () => {
  const navigate = useNavigate();
  const { 
    loggedIn, user, setShowAddPetsModal
  } = useContext(StateContext);

  const handleBookingButtonClick = () => {
    if (loggedIn) {
      const hasPets = user.familyMembers.some(member => member.type === 'Dog' || member.type === 'Cat');
      if (hasPets) {
        navigate(`/store/services/adventure/booking`);
      } else {
        setShowAddPetsModal(true);
      }
    } else {
      navigate(`/login`);
    }
    window.scrollTo(0, 0);
  }

  return (
    <>
      <div className='adventure-background-one-container'>
        <div className='container'>
          <div className="adventure-container">
            <img className='adventure-page-icon' src={daycarePageIcon} />
            <h2 className='adventure-page-heading'>Waves & Trails: Pet-Focused Adventure</h2>
            <p className='adventure-page-paragraph'>Tailored adventures for your pets to discover, play, and enjoy safely. It's all about fun and safety.</p>
            <div className="centered-container">
              <button className="adventure-page-button" onClick={handleBookingButtonClick}>BOOK A TRIP</button>
            </div>
            <img className='adventure-page-main-photo' src={pageMainPhoto} />
          </div>
        </div>
      </div>  

      <div className='adventure-background-two-container'>
        <div className='container'>
          <h2 className='adventure-subpage-heading'>Adventure Activities</h2>
          <ul>
            <li>
              <div className="adventure-services-item">
                <img className="adventure-services-item-icon" src={hikingIcon}/>
                <div className="adventure-services-item-content">
                  <h4 className="adventure-services-item-heading">Hiking Excursions</h4>
                  <p className="adventure-services-item-paragraph">
                    Embark on guided hiking excursions with your pets to explore nature, breathe in fresh air, and enjoy breathtaking views together.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="adventure-services-item">
                <img className="adventure-services-item-icon" src={beachIcon}/>
                <div className="adventure-services-item-content">
                  <h4 className="adventure-services-item-heading">Beach Playtime</h4>
                  <p className="adventure-services-item-paragraph">
                    Treat your pets to a day of beach playtime, where they can splash in the waves, romp on sandy shores, and bask in the sun's warmth.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="adventure-services-item">
                <img className="adventure-services-item-icon" src={parkIcon}/>
                <div className="adventure-services-item-content">
                  <h4 className="adventure-services-item-heading">Park Adventures</h4>
                  <p className="adventure-services-item-paragraph">
                    Join our park adventures, offering ample space for dogs to run, play fetch, and interact with other friendly pups in a safe and supervised environment.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="adventure-services-item">
                <img className="adventure-services-item-icon" src={forestIcon}/>
                <div className="adventure-services-item-content">
                  <h4 className="adventure-services-item-heading">Forest Exploration</h4>
                  <p className="adventure-services-item-paragraph">
                    Explore the enchanting wilderness with your pets, where they can roam amidst towering trees, rustling leaves, and the wonders of the forest.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="adventure-services-item">
                <img className="adventure-services-item-icon" src={mountainIcon}/>
                <div className="adventure-services-item-content">
                  <h4 className="adventure-services-item-heading">Mountain Escapades</h4>
                  <p className="adventure-services-item-paragraph">
                    Climb to new heights with your pets on mountain escapades, offering breathtaking vistas and unforgettable challenges for both of you.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div className='adventure-background-three-container'>
        <div className='container'>
          <h2 className='adventure-safety-heading'>Safety and Security Measures</h2>
          <ul>
            <li>
              <div className="adventure-services-item">
                <img className="adventure-services-item-icon" src={photoIcon} alt="Photos Icon"/>
                <div className="adventure-services-item-content">
                  <h4 className="adventure-services-item-heading">Memorable Photos</h4>
                  <p className="adventure-services-item-paragraph">
                    Capture the adventure moments! We take lots of photos during your pet's adventure so you can cherish the memories forever.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="adventure-services-item">
                <img className="adventure-services-item-icon" src={essestialsIcon}/>
                <div className="adventure-services-item-content">
                  <h4 className="adventure-services-item-heading">Adventure Essentials</h4>
                  <p className="adventure-services-item-paragraph">
                    We provide adventure essentials like water, treats, and waste bags to keep your pets comfortable and prepared throughout their adventure journey.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="adventure-services-item">
                <img className="adventure-services-item-icon" src={leashIcon}/>
                <div className="adventure-services-item-content">
                  <h4 className="adventure-services-item-heading">Leash Policy</h4>
                  <p className="adventure-services-item-paragraph">
                    During adventures, we ensure pets are safely leashed to prevent any accidents and maintain control over their movements.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="adventure-services-item">
                <img className="adventure-services-item-icon" src={groupSizeIcon}/>
                <div className="adventure-services-item-content">
                  <h4 className="adventure-services-item-heading">Limited Group Size</h4>
                  <p className="adventure-services-item-paragraph">
                    To maintain a safe and enjoyable adventure experience, we limit the group size for each adventure, ensuring personalized attention for your pet.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="adventure-services-item">
                <img className="adventure-services-item-icon" src={emergencyIcon} alt="Emergency Protocol Icon"/>
                <div className="adventure-services-item-content">
                  <h4 className="adventure-services-item-heading">Emergency Protocols</h4>
                  <p className="adventure-services-item-paragraph">
                    Your pet's safety is our top priority. We have established emergency protocols to handle any unexpected situations during adventures.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div className='adventure-background-four-container'>
        <LetsGetStarted />
      </div>

      <Footer />

      <BookingSideIcon handleIconClick={handleBookingButtonClick} />
    </>
  );
};

export default Adventure;


{/* <div className="adventure-container">
<h2>Adventure Description:</h2>
<p>Embark on exciting adventures with Pawsible Adventure! Our adventure programs are tailored to provide outdoor exploration and fun-filled activities for your pets. Whether it's hiking through scenic trails, playing at the beach, or exploring the great outdoors, your furry companions are sure to have a thrilling experience.</p>

<h2>Adventure Activities:</h2>
<ul>
  <li>Hiking Excursions: Join our guided hiking trips where your dogs can explore nature and enjoy the fresh air and breathtaking views.</li>
  <li>Beach Playtime: Take your pets to the beach for a splash in the waves and a playful romp on the sandy shores.</li>
  <li>Park Adventures: Our park outings offer ample space for dogs to run, play fetch, and interact with other friendly pups.</li>
</ul>

<h2>Adventure Safety:</h2>
<ul>
  <li>Leash Policy: During adventures, we ensure pets are safely leashed to prevent any accidents and maintain control.</li>
  <li>Group Size: To maintain a safe and enjoyable experience, we limit the group size for each adventure.</li>
  <li>Preparation: We provide essentials like water, treats, and waste bags to keep your pets comfortable throughout the journey.</li>
</ul>

<p>With Pawsible Adventure, your pets can explore new horizons, make furry friends, and create unforgettable memories. Join us on our next adventure and let your pets experience the joy of outdoor exploration!</p>
</div> */}