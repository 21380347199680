import React from "react";
import "./BookingSideIcon.css";

const BookingSideIcon = ({ handleIconClick }) => {
    return (
        <div className="booking-side-icon-container" onClick={handleIconClick}>
            <span className="booking-side-icon-text">Start</span>
        </div>
    );
};

export default BookingSideIcon;
