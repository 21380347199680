import React, { useContext } from 'react';
import './Shuttle.css';
import { useNavigate } from 'react-router-dom';
import { StateContext } from '../../../StateProvider';
import BookingSideIcon from '../../../Utilities/components/BookingSideIcon';

import groomingPageIcon from '../../../../assets/Store/Services/Grooming/groomingPageIcon.png';
import pageMainPhoto from '../../../../assets/Store/Services/pageMainPhoto.png';

import comfortIcon from '../../../../assets/Store/Services/Shuttle/shuttleComfortIcon.png';
import experienceIcon from '../../../../assets/Store/Services/Shuttle/shuttleExperienceIcon.png';
import scheduleIcon from '../../../../assets/Store/Services/Shuttle/shuttleScheduleIcon.png';
import luxuryIcon from '../../../../assets/Store/Services/Shuttle/shuttleLuxuryIcon.png';
import teslaIcon from '../../../../assets/Store/Services/Shuttle/shuttleTeslaIcon.png';

import safetyIcon from '../../../../assets/Store/Services/Shuttle/shuttleSafetyIcon.png';
import hygieneIcon from '../../../../assets/Store/Services/Shuttle/shuttleHygieneIcon.png';
import inspectionIcon from '../../../../assets/Store/Services/Shuttle/shuttleInspectionIcon.png';
import trainedIcon from '../../../../assets/Store/Services/Shuttle/shuttleTrainedIcon.png';

import Footer from '../../../Footer';
import LetsGetStarted from '../../../Utilities/components/LetsGetStarted';

const Shuttle = () => {
  const navigate = useNavigate();
  const { 
    loggedIn, user, setShowAddPetsModal
  } = useContext(StateContext);

  const handleBookingButtonClick = () => {
    if (loggedIn) {
      const hasPets = user.familyMembers.some(member => member.type === 'Dog' || member.type === 'Cat');
      if (hasPets) {
        navigate(`/store/services/shuttle/booking`);
      } else {
        setShowAddPetsModal(true);
      }
    } else {
      navigate(`/login`);
    }
    window.scrollTo(0, 0);
  }

  return (
    <>
      <div className='transportation-background-one-container'>
        <div className='container'>
          <div className="transportation-container">
            <img className='transportation-page-icon' src={groomingPageIcon} />
            <h2 className='transportation-page-heading'>Pawsible Transports: Ensuring Comfort in Every Journey</h2>
            <p className='transportation-page-paragraph'>Safe, comfortable, and reliable transportation for your pets en route to their adventures.</p>
            <div className="centered-container">
              <button className="transportation-page-button" onClick={handleBookingButtonClick}>BOOK A RIDE</button>
            </div>
            <img className='transportation-page-main-photo' src={pageMainPhoto} />
          </div>
        </div>
      </div>  

      <div className='transportation-background-two-container'>
        <div className='container'>
          <h2 className='transportation-subpage-heading'>WHY CHOOSE PAWSIBLE TRANSPORTS</h2>
          <h3 className='transportation-subpage-heading-3'>Shuttle Features</h3>
          <ul>
            <li>
              <div className="transportation-services-item">
                <img className="transportation-services-item-icon" src={comfortIcon}/>
                <div className="transportation-services-item-content">
                  <h4 className="transportation-services-item-heading">Comfortable Cabins</h4>
                  <p className="transportation-services-item-paragraph">
                    Spacious and cozy cabins thoughtfully designed to provide your pets with maximum comfort, ensuring a relaxed and stress-free journey.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="transportation-services-item">
                <img className="transportation-services-item-icon" src={experienceIcon}/>
                <div className="transportation-services-item-content">
                  <h4 className="transportation-services-item-heading">Experienced Staff</h4>
                  <p className="transportation-services-item-paragraph">
                    Extensively trained in pet care and safety protocols, guaranteeing a smooth and secure ride for your beloved pets.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="transportation-services-item">
                <img className="transportation-services-item-icon" src={scheduleIcon}/>
                <div className="transportation-services-item-content">
                  <h4 className="transportation-services-item-heading">Convenient Schedule</h4>
                  <p className="transportation-services-item-paragraph">
                    flexible pick-up and drop-off schedules, tailored to accommodate your busy lifestyle.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="transportation-services-item">
                <img className="transportation-services-item-icon" src={luxuryIcon}/>
                <div className="transportation-services-item-content">
                  <h4 className="transportation-services-item-heading">Luxury Experience</h4>
                  <p className="transportation-services-item-paragraph">
                    With luxury vehicles, your pets can enjoy a quiet, smooth, and fabulous ride, reflecting the highest standards of comfort and care in pet transportation.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="transportation-services-item">
                <img className="transportation-services-item-icon" src={teslaIcon}/>
                <div className="transportation-services-item-content">
                  <h4 className="transportation-services-item-heading">Tesla Electric Fleet</h4>
                  <p className="transportation-services-item-paragraph">
                    We utilize Tesla electric vehicles for sustainable, eco-friendly, and smooth transport.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div className='transportation-background-three-container'>
        <div className='container'>
          <h2 className='transportation-safety-heading'>SAFETY IS OUR PRIORITY</h2>
          <h3 className='transportation-safety-heading-3'>Transportation Safety</h3>
          <ul>
            <li>
              <div className="transportation-services-item">
                <img className="transportation-services-item-icon" src={safetyIcon}/>
                <div className="transportation-services-item-content">
                  <h4 className="transportation-services-item-heading">Safe Travel</h4>
                  <p className="transportation-services-item-paragraph">
                    Safety measures in place to ensure secure transport in all conditions.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="transportation-services-item">
                <img className="transportation-services-item-icon" src={hygieneIcon}/>
                <div className="transportation-services-item-content">
                  <h4 className="transportation-services-item-heading">Hygiene</h4>
                  <p className="transportation-services-item-paragraph">
                    Adhering to the highest standards of cleanliness and hygiene in all our shuttles.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="transportation-services-item">
                <img className="transportation-services-item-icon" src={inspectionIcon}/>
                <div className="transportation-services-item-content">
                  <h4 className="transportation-services-item-heading">Vehicle Inspections</h4>
                  <p className="transportation-services-item-paragraph">
                    Regular maintenance and safety inspections to ensure all vehicles are in top condition.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="transportation-services-item">
                <img className="transportation-services-item-icon" src={trainedIcon}/>
                <div className="transportation-services-item-content">
                  <h4 className="transportation-services-item-heading">Trained Drivers</h4>
                  <p className="transportation-services-item-paragraph">
                    Drivers trained in pet care and emergency protocols for a safe and secure journey.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div className='transportation-background-four-container'>
        <LetsGetStarted />
      </div>

      <BookingSideIcon handleIconClick={handleBookingButtonClick} />

      <Footer />

    </>
  );
};

export default Shuttle;