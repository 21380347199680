import React, { useState, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { StateContext } from '../../../../../../StateProvider';
import { BackButton } from '../../../../../../Utilities/components/Buttons';
import { updateTransaction, cancelTransaction } from '../../../../../../Utilities/api';
import './PaymentDetails.css';

const PaymentDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, refreshUserData } = useContext(StateContext);
  const { transaction } = location.state;
  const [notes, setNotes] = useState(transaction.specialNotes || '');
  const [additionalServices, setAdditionalServices] = useState(transaction.additionalServices || {});
  const [showCancelModal, setShowCancelModal] = useState(false);

  if (!transaction) {
    return <div>No transaction details available.</div>;
  }

  const handleUpdate = async () => {
    const updatedTransaction = {
      ...transaction,
      specialNotes: notes,
      additionalServices: additionalServices
    };

    try {
      await updateTransaction(transaction.id, updatedTransaction);
      navigate(-1);
    } catch (error) {
      console.error('Error updating transaction:', error);
    }
  };

  const handleContactSupport = () => {
    navigate('/support');
  };

  const handleCancelClick = () => {
    setShowCancelModal(true); // Show the confirmation modal
  };
  
  const confirmCancel = async () => {
    setShowCancelModal(false); // Hide modal after confirmation
  
    try {
      await cancelTransaction(transaction.id);
      refreshUserData(user.uid);
      navigate(-1);
    } catch (error) {
      console.error('Error cancelling booking:', error);
    }
  };

  const camelCaseToPhrase = (str) => {
    return str
      .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space before capital letters
      .replace(/^./, (match) => match.toUpperCase()); // Capitalize the first letter
  };

  const renderAdditionalServices = (services) => {
    return Object.keys(services).map((key) => {
      const value = services[key];
      return (
        <div key={key} className="payment-details-additional-service">
          <p><strong>{camelCaseToPhrase(key)}:</strong></p>
          {typeof value === 'boolean' ? (
            <p>&nbsp;{value.toString()}</p>
          ) : (
            <p>&nbsp;{value}</p>
          )}
        </div>
      );
    });
  };

  return (
    <div className="container">
      <BackButton onClick={() => navigate(-1)} />
      <h1 className='payment-details-header'>Transaction Details</h1>
      <div className="payment-details-information">
        <p><strong>Booking ID:</strong> {transaction.id}</p>
        <p><strong>Service:</strong> {transaction.bookingType}</p>
        <p>
          <strong>Guests:</strong> {transaction.selectedPets && transaction.selectedPets.length > 0 
            ? transaction.selectedPets.map(pet => pet.petName).join(', ')
            : 'N/A'}
        </p>
        <p><strong>Start:</strong> {transaction.startTime ? new Date(transaction.startTime).toLocaleString() : 'N/A'}</p>
        <p><strong>End:</strong> {transaction.endTime ? new Date(transaction.endTime).toLocaleString() : 'N/A'}</p>
        <p><strong>Price:</strong> ${parseFloat(transaction.discountedPrice || transaction.originalPrice).toFixed(2)}</p>
        <p><strong>Status:</strong> {transaction.status}</p>
        <div className="payment-details-additional-services">
          {additionalServices.shuttleOptions && (
            <div className='payment-details-additional-services-container'>
              <h2>Shuttle Details</h2>
              {renderAdditionalServices(additionalServices.shuttleOptions)}
            </div>
          )}
          {additionalServices.additionalServices && (
            <div className='payment-details-additional-services-container'>
              <h2>Daycare Details</h2>
              {renderAdditionalServices(additionalServices.additionalServices)}
            </div>
          )}
          {additionalServices.trainingOptions && (
            <div className='payment-details-additional-services-container'>
              <h2>Training Details</h2>
              {renderAdditionalServices(additionalServices.trainingOptions)}
            </div>
          )}
        </div>
        <p><strong>Special Notes:</strong></p>
        <textarea
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          className="payment-details-special-notes-textarea"
        />
      </div>
      <div className='payment-details-buttons-container'>
        {transaction.status === 'Pending' && (
          <button onClick={handleUpdate} className="payment-details-update-button">Update</button>
        )}
        <button onClick={handleContactSupport} className="payment-details-contact-support-button">Help</button>
        {transaction.status !== 'Canceled' && (
          <button
            onClick={handleCancelClick}
            className="payment-details-cancel-booking-button"
            disabled={new Date(transaction.endTime) < new Date()} // Check if endTime is past
          >
            Cancel
          </button>
        )}
      </div>

      {/* Cancel Confirmation Modal */}
      {showCancelModal && (
        <div className="booking-cancel-modal-overlay">
          <div className="booking-cancel-modal">
            <h2 className="add-pet-modal-title">Confirm Cancellation</h2>
            <p className="add-pet-modal-message">Are you sure you want to cancel this booking?</p>
            <div className="modal-button-group">
              <button onClick={confirmCancel} className="booking-cancel-confirm-button btn-close-red">
                Yes, Cancel
              </button>
              <button onClick={() => setShowCancelModal(false)} className="booking-cancel-cancel-button btn-close-blue">
                No, Go Back
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentDetails;
